<template>
    <div class="refundReport">
        <div class="header flex">
            <div class="header-title"></div>
            <div class="header-search">
                <!--<div style="display:inline-block;position: relative">-->
                <!--<person class="person" v-bind="personObj" @getData="_relevanceObject"></person>-->
                <!--<a-input v-model="relevanceText" placeholder="区域" style="width:150px;margin-right:15px"></a-input>-->
                <!--</div>-->
                <a-tree-select
                        class="search-style"
                        style="width: 300px;margin-right: 20px"
                        :tree-data="treeData"
                        tree-checkable
                        :maxTagCount="1"
                        placeholder="请选择搜索范围"
                        @change="treechange"
                        v-model="treeIds"
                        :show-checked-strategy="SHOW_PARENT"
                />

                <a-range-picker
                        v-model="searchDate"
                        style="width: 240px;margin-right: 15px;"
                />
                <a-button @click="_reset">重置</a-button>
                <a-button type="primary" style="margin-left: 15px;" @click="_search"
                >确定
                </a-button
                >
            </div>
        </div>

        <div class="container flex" style="align-items: end;">
            <div class="main">
                <div class="summary" v-if="infoData">
                    <ul class="flex">
                        <li>
                            <div class="item-title">退费金额</div>
                            <div>
                                <span class="sum">{{ infoData.static.refund }}</span>
                                <span class="sup">
                  <span
                  ><span style="position: relative;top:-4px;">↑</span>+</span
                  >
                  <span>{{ infoData.static.dayrefund }}</span>
                </span>
                            </div>
                        </li>
                        <li>
                            <div class="item-title">业绩金额</div>
                            <div>
                                <span class="sum">{{ infoData.static.money }}</span>
                                <span class="sup">
                  <span
                  ><span style="position: relative;top:-4px;">↑</span>+</span
                  >
                  <span>{{ infoData.static.daymoney }}</span>
                </span>
                            </div>
                        </li>
                        <li>
                            <div class="item-title">退费意向</div>
                            <div>
                                <span class="sum">{{ infoData.static.refundintention }}</span>
                                <span class="sup">
                  <span
                  ><span style="position: relative;top:-4px;">↑</span>+</span
                  >
                  <span>{{ infoData.static.dayrefundintention }}</span>
                </span>
                            </div>
                        </li>
                        <li>
                            <div class="item-title">退费率</div>
                            <div>
                <span class="sum">
                  {{ infoData.rate }}{{ infoData.rate > 0 ? '%' : '' }}</span
                >
                            </div>
                        </li>

                        <li>
                            <div class="item-title">本月剩余退费额度</div>
                            <div><span class="sum">{{infoData.static.Quota}}</span></div>
                        </li>
                    </ul>
                </div>
                <div class="eaharts">
                    <div class="eaharts-header">退费意向走势图</div>
                    <div class="eaharts-totality" v-if="infoData">
                        <ul class="flex">
                            <li>
                                <div class="tab-title">
                                    <span class="tab-icon" style="background: #FCC6A3"></span>
                                    <span>低</span>
                                </div>
                                <div class="tab-money">{{ infoData.money.low }}</div>
                            </li>
                            <li>
                                <div class="tab-title">
                                    <span class="tab-icon" style="background: #F18069"></span>
                                    <span>中</span>
                                </div>
                                <div class="tab-money">{{ infoData.money.mid }}</div>
                            </li>
                            <li>
                                <div class="tab-title">
                                    <span class="tab-icon" style="background: #F15247"></span>
                                    <span>高</span>
                                </div>
                                <div class="tab-money">{{ infoData.money.high }}</div>
                            </li>
                            <li>
                                <div class="tab-title">
                                    <span class="tab-icon" style="background: #CC1131"></span>
                                    <span>超级高</span>
                                </div>
                                <div class="tab-money">{{ infoData.money.superhigh }}</div>
                            </li>
                            <li>
                                <div class="tab-title">
                                    <img
                                            src="https://smart-resource.sikegroup.com/refundReport-icon-2.png"
                                            alt=""
                                    />
                                    <span>审批中</span>
                                </div>
                                <div class="tab-money">{{ infoData.static.task }}</div>
                            </li>
                            <!--<li>-->
                            <!--<div class="tab-title"><img src="https://smart-resource.sikegroup.com/refundReport-icon-1.png" alt=""> <span>已退费</span></div>-->
                            <!--<div class="tab-money">{{infoData.static.refund}}</div>-->
                            <!--</li>-->
                        </ul>
                        <div style="display: flex;margin-left: 80%;">
                            <div
                                    v-if="infoData.static.task1 != 0 || infoData.static.task4 != 0"
                            >
                                <span style="color: #9E9E9E;font-size: 12px">其它:</span>
                                <span style="color: #000;font-size: 12px">{{
                  infoData.static.task1
                }}</span>
                            </div>
                            <div
                                    style="margin-left: 20px"
                                    v-if="infoData.static.task1 != 0 || infoData.static.task4 != 0"
                            >
                                <span style="color: #9E9E9E;font-size: 12px">四期:</span>
                                <span style="color: #000;font-size: 12px">{{
                  infoData.static.task4
                }}</span>
                            </div>
                        </div>
                    </div>
                    <div id="eaharts" style="height: 330px;width:94%"></div>
                </div>
                <div class="ranking flex">
                    <!--城市退费排名-->
                    <div class="list">
                        <div class="title" style="border: none;padding-bottom:0">
                            城市退费排名
                        </div>
                        <ul>
                            <li class="flex" style="padding-top:0;padding-bottom:3px;">
                                <div>
                                    <span class="num" style="opacity: 0;height:0"></span>
                                    <span class="centre-name" style="opacity: 0"></span>
                                </div>
                                <div>
                  <span class="money min-title"
                  >退费金额
                    <a-icon
                            type="caret-up"
                            @click="
                        _sort(infoData.refundcitys, 'Money', 'refundcitys', '')
                      "/>
                    <a-icon
                            type="caret-down"
                            @click="
                        _sort(
                          infoData.refundcitys,
                          'Money',
                          'refundcitys',
                          'below'
                        )
                      "
                    /></span>
                                    <span class="sup min-title"
                                    >今日新增
                    <a-icon
                            type="caret-up"
                            @click="
                        _sort(infoData.refundcitys, 'Up', 'refundcitys', '')
                      "/>
                    <a-icon
                            type="caret-down"
                            @click="
                        _sort(
                          infoData.refundcitys,
                          'Up',
                          'refundcitys',
                          'below'
                        )
                      "
                    /></span>
                                </div>
                                <div class="money min-title">
                                    退费率
                                    <a-icon
                                            type="caret-up"
                                            @click="
                      _sort(infoData.refundcitys, 'Rate', 'refundcitys', '')
                    "
                                    />
                                    <a-icon
                                            type="caret-down"
                                            @click="
                      _sort(
                        infoData.refundcitys,
                        'Rate',
                        'refundcitys',
                        'below'
                      )
                    "
                                    />
                                </div>
                            </li>

                            <li class="flex" v-for="(item, index) in infoData.refundcitys">
                                <div style="white-space: nowrap">
                                    <span class="num">{{ index + 1 }}</span>
                                    <span class="centre-name">{{ item.CityName }}</span>
                                </div>
                                <div>
                                    <span class="money">{{ item.Money }}</span>
                                    <span class="sup">
                    <span
                    ><span style="position: relative;top:-4px;">↑</span
                    >+</span
                    >
                    <span>{{ item.Up }}</span>
                  </span>
                                </div>
                                <div class="money">{{ item.Rate }}%</div>
                            </li>
                        </ul>
                    </div>
                    <!--城市意向排名-->
                    <div class="list" style="margin-left: 15px;">
                        <div class="title" style="border: none;padding-bottom:0">
                            城市意向排名
                        </div>
                        <ul>
                            <li class="flex" style="padding-top:0;padding-bottom:3px;">
                                <div>
                                    <span class="centre-name"></span>
                                </div>
                                <div>
                  <span class="money min-title"
                  >退费金额
                    <a-icon
                            type="caret-up"
                            @click="_sort(infoData.citys, 'Money', 'citys', '')"/>
                    <a-icon
                            type="caret-down"
                            @click="_sort(infoData.citys, 'Money', 'citys', 'below')"
                    /></span>
                                    <span class="sup min-title"
                                    >今日新增
                    <a-icon
                            type="caret-up"
                            @click="_sort(infoData.citys, 'Up', 'citys', '')"/>
                    <a-icon
                            type="caret-down"
                            @click="_sort(infoData.citys, 'Up', 'citys', 'below')"
                    /></span>
                                </div>
                            </li>
                            <li class="flex" v-for="(item, index) in infoData.citys">
                                <div>
                                    <span class="num">{{ index + 1 }}</span>
                                    <span class="centre-name">{{ item.CityName }}</span>
                                </div>
                                <div>
                                    <span class="money">{{ item.Money }}</span>
                                    <span class="sup">
                    <span
                    ><span style="position: relative;top:-4px;">↑</span
                    >+</span
                    >
                    <span>{{ item.Up }}</span>
                  </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="ranking flex">
                    <!--中心退费排名-->
                    <div class="list">
                        <div class="title" style="border: none;padding-bottom:0">
                            中心退费排名
                        </div>
                        <ul>
                            <li class="flex" style="padding-top:0;padding-bottom:3px;">
                                <div>
                                    <span class="num" style="opacity: 0;height:0"></span>
                                    <span class="centre-name" style="opacity: 0"></span>
                                </div>
                                <div>
                  <span class="money min-title"
                  >退费金额
                    <a-icon
                            type="caret-up"
                            @click="
                        _sort(
                          infoData.refundschools,
                          'Money',
                          'refundschools',
                          ''
                        )
                      "/>
                    <a-icon
                            type="caret-down"
                            @click="
                        _sort(
                          infoData.refundschools,
                          'Money',
                          'refundschools',
                          'below'
                        )
                      "
                    /></span>
                                    <span class="sup min-title"
                                    >今日新增
                    <a-icon
                            type="caret-up"
                            @click="
                        _sort(infoData.refundschools, 'Up', 'refundschools', '')
                      "/>
                    <a-icon
                            type="caret-down"
                            @click="
                        _sort(
                          infoData.refundschools,
                          'Up',
                          'refundschools',
                          'below'
                        )
                      "
                    /></span>
                                </div>
                                <div class="money min-title">
                                    退费率
                                    <a-icon
                                            type="caret-up"
                                            @click="
                      _sort(infoData.refundschools, 'Rate', 'refundschools', '')
                    "
                                    />
                                    <a-icon
                                            type="caret-down"
                                            @click="
                      _sort(
                        infoData.refundschools,
                        'Rate',
                        'refundschools',
                        'below'
                      )
                    "
                                    />
                                </div>
                            </li>

                            <li class="flex" v-for="(item, index) in infoData.refundschools">
                                <div>
                                    <span class="num">{{ index + 1 }}</span>
                                    <span class="centre-name">{{ item.SchoolName }}</span>
                                </div>
                                <div>
                                    <span class="money">{{ item.Money }}</span>
                                    <span class="sup">
                    <span
                    ><span style="position: relative;top:-4px;">↑</span
                    >+</span
                    >
                    <span>{{ item.Up }}</span>
                  </span>
                                </div>
                                <div class="money">{{ item.Rate }}%</div>
                            </li>
                        </ul>
                    </div>
                    <!--中心意向排名-->
                    <div class="list" style="margin-left: 15px;">
                        <div class="title" style="border: none;padding-bottom:0">
                            中心意向排名
                        </div>
                        <ul>
                            <li class="flex" style="padding-top:0;padding-bottom:3px;">
                                <div>
                                    <span class="centre-name"></span>
                                </div>
                                <div>
                  <span class="money min-title"
                  >退费金额
                    <a-icon
                            type="caret-up"
                            @click="_sort(infoData.schools, 'Money', 'schools', '')"/>
                    <a-icon
                            type="caret-down"
                            @click="
                        _sort(infoData.schools, 'Money', 'schools', 'below')
                      "
                    /></span>
                                    <span class="sup min-title"
                                    >今日新增
                    <a-icon
                            type="caret-up"
                            @click="_sort(infoData.schools, 'Up', 'schools', '')"/>
                    <a-icon
                            type="caret-down"
                            @click="_sort(infoData.schools, 'Up', 'schools', 'below')"
                    /></span>
                                </div>
                                <!--<div class="money min-title">退费率</div>-->
                            </li>
                            <li class="flex" v-for="(item, index) in infoData.schools">
                                <div>
                                    <span class="num">{{ index + 1 }}</span>
                                    <span class="centre-name">{{ item.SchoolName }}</span>
                                </div>
                                <div>
                                    <span class="money">{{ item.Money }}</span>
                                    <span class="sup">
                    <span
                    ><span style="position: relative;top:-4px;">↑</span
                    >+</span
                    >
                    <span>{{ item.Up }}</span>
                  </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="ranking flex" style="background: #fff;border-radius: 5px;">
                    <div
                            id="eaharts2"
                            style="height: 330px;width:100%;padding: 15px;"
                    ></div>
                </div>
            </div>
            <div class="sidebar">
                <div class="list">
                    <div class="title flex" style="align-items: center">
                        <span>意向动态</span>
                        <span
                                style="font-size: 14px;color:#999;cursor: pointer"
                                @click="_showVisible(1)"
                        >更多 >></span
                        >
                    </div>
                    <ul>
                        <li
                                v-for="(item, index) in infoData.students"
                                @click="_skipSkim(item.StudentId, item.StudentName)"
                        >
                            <div class="flex">
                                <div class="money">
                                    退费金额：<span>-{{ item.Money }}</span>
                                </div>
                                <a-popover title="备注信息">
                                    <template slot="content">
                                        {{ item.Remark }}
                                    </template>
                                    <img
                                            src="https://smart-resource.sikegroup.com/refundReport-3.png"
                                            v-if="item.Remark"
                                            style="cursor: pointer"
                                            alt=""
                                    />
                                </a-popover>
                            </div>
                            <div class="flex" style="padding: 7px 0;">
                                <div class="flex">
                                    <img
                                            src="https://smart-resource.sikegroup.com/customer-user-img_0.png"
                                            class="user-img"
                                            alt=""
                                    />
                                    <div>
                                        <div class="user-msg">
                      <span
                              class="icon-btn"
                              :style="
                          item.RefundIntention == 1
                            ? 'background: rgb(252, 198, 163)'
                            : item.RefundIntention == 2
                            ? 'background: rgb(241, 128, 105)'
                            : item.RefundIntention == 3
                            ? 'background:rgb(241, 82, 71)'
                            : 'background:rgb(204, 17, 49)'
                        "
                      ></span>
                                            <span class="user-name">{{ item.StudentName }}</span>
                                            <span class="user-centre">{{ item.SchoolName }}</span>
                                        </div>
                                        <div class="date">
                                            <a-icon type="history"/>
                                            {{ item.Thedate }}
                                        </div>
                                    </div>
                                </div>
                                <a-icon type="right" class="right-icon"/>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="list" style="margin-top: 15px;">
                    <div class="title flex" style="align-items: center">
                        <span>退费审批</span>
                        <span
                                style="font-size: 14px;color:#999;cursor: pointer"
                                @click="_showVisible(2)"
                        >更多 >></span
                        >
                    </div>
                    <ul>
                        <li
                                v-for="(item, index) in infoData.tasks"
                                @click="_skipSkimSP(item.TaskId, item.TaskName)"
                        >
                            <div class="flex">
                                <div class="money">
                                    退费金额：<span>-{{ item.Money }}</span>
                                </div>
                                <a-popover title="备注信息">
                                    <template slot="content">
                                        {{ item.Remark }}
                                    </template>
                                    <img
                                            src="https://smart-resource.sikegroup.com/refundReport-3.png"
                                            v-if="item.Remark"
                                            style="cursor: pointer"
                                            alt=""
                                    />
                                </a-popover>
                            </div>
                            <div class="flex" style="padding: 7px 0;">
                                <div class="flex">
                                    <img
                                            src="https://smart-resource.sikegroup.com/customer-user-img_0.png"
                                            class="user-img"
                                            alt=""
                                    />
                                    <div>
                                        <div class="user-msg">
                                            <img
                                                    src="https://smart-resource.sikegroup.com/refundReport-icon-2.png"
                                                    style="width: 20px;height:20px;vertical-align:sub;margin-right:4px;"
                                                    alt=""
                                            />
                                            <span class="user-name">{{ item.StudentName }}</span>
                                            <span class="user-centre">{{ item.SchoolName }}</span>
                                        </div>
                                        <div class="date">
                                            <img
                                                    src="https://smart-resource.sikegroup.com/refundReport.png"
                                                    alt=""
                                                    v-if="item.SchoolName"
                                            />
                                            {{ item.SchoolName }}
                                            <span style="color: #FF0000;margin-left:10px;">{{
                        item.RefundMoney
                      }}</span>
                                            / <span>{{ item.SchoolMoney }}</span>
                                        </div>
                                    </div>
                                </div>
                                <a-icon type="right" class="right-icon"/>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <a-drawer
                title="更多数据"
                placement="right"
                width="1000"
                :closable="false"
                @close="_drawerClose"
                :visible="visible"
        >
            <div style="padding-bottom: 20px;">
                <a-table
                        :columns="refundColumns"
                        :dataSource="refundList"
                        :pagination="false"
                >
                    <div slot="sNumber" slot-scope="text, record, index">
                        {{ index + 1 }}
                    </div>
                </a-table>
            </div>
        </a-drawer>
        <!--上传模板弹出框-->
        <a-drawer
                :title="templateTitle"
                placement="right"
                width="600"
                :closable="false"
                @close="_templateClose"
                :visible="templateVisible"
        >
            <div class="draw">
                <div class="list">
                    <ul>
                        <li
                                v-for="(item, index) in templateData"
                                @click="_skipSkimSP(item.Id, item.Name)"
                        >
                            <div class="flex">
                                <div class="money">
                                    退费金额：<span>-{{ item.Money }}</span>
                                </div>
                                <a-popover title="备注信息">
                                    <template slot="content">
                                        {{ item.Content }}
                                    </template>
                                    <img
                                            src="https://smart-resource.sikegroup.com/refundReport-3.png"
                                            v-if="item.Content"
                                            style="cursor: pointer"
                                            alt=""
                                    />
                                </a-popover>
                            </div>
                            <div class="flex" style="padding: 7px 0;">
                                <div class="flex">
                                    <img
                                            src="https://smart-resource.sikegroup.com/customer-user-img_0.png"
                                            class="user-img"
                                            alt=""
                                    />
                                    <div>
                                        <div class="user-msg">
                      <span
                              class="icon-btn"
                              :style="
                          item.RefundIntention == 1
                            ? 'background: rgb(252, 198, 163)'
                            : item.RefundIntention == 2
                            ? 'background: rgb(241, 128, 105)'
                            : item.RefundIntention == 3
                            ? 'background:rgb(241, 82, 71)'
                            : 'background:rgb(204, 17, 49)'
                        "
                      ></span>
                                            <span class="user-name">{{ item.StudentName }}</span>
                                            <span class="user-centre">{{ item.SchoolName }}</span>
                                        </div>
                                        <div class="date">
                                            <a-icon type="history"/>
                                            {{ item.Thedate }}
                                        </div>
                                    </div>
                                </div>
                                <a-icon type="right" class="right-icon"/>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    import person from 'components/SelectPersonnel/SelectPersonnel' //选人
    export default {
        name: 'refundReport',
        components: {
            person
        },
        data() {
            return {
                visible: false,
                personObj: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 10000, //选的数量
                    department: 2, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: '', //常用联系人 不要常用联系人传空''
                    selectArr: this.relevanceObject || [] //要传入的数据
                },
                infoData: '',
                relevanceText: '',
                searchDate: [],
                searchId: '',

                refundColumns: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        rowKey: 'sNumber',
                        align: 'center',
                        width: 80,
                        scopedSlots: {customRender: 'sNumber'}
                    },
                    {
                        title: '名称',
                        dataIndex: 'StudentName',
                        rowKey: 'StudentName',
                        align: 'center'
                    },
                    {
                        title: '所属中心',
                        dataIndex: 'SchoolName',
                        rowKey: 'SchoolName',
                        align: 'center'
                    },
                    {
                        title: '退费金额',
                        dataIndex: 'Money',
                        rowKey: 'Money',
                        align: 'center',
                        sorter: (a, b) => a.Money - b.Money
                    },
                    {
                        title: '退费时间',
                        dataIndex: 'Thedate',
                        rowKey: 'Thedate',
                        align: 'center'
                    },
                    {
                        title: '备注',
                        width: 400,
                        dataIndex: 'Remark',
                        rowKey: 'Remark',
                        align: 'center'
                    }
                ],
                refundList: [],
                refundTasksList: [],
                SHOW_PARENT: 'SHOW_ALL',
                treeData: [
                    {
                        title: '七田真事业部',
                        value: '7520669f3b20008c6a6df71e9cebc2f9',
                        key: '3',
                        children: [
                            {
                                title: 'Child Node1',
                                value: '7520669f3b20008c6a6df71e9cebc2f944',
                                key: '4'
                            }
                        ]
                    },
                    {
                        title: 'Node2',
                        value: '6',
                        key: '7',
                        children: [
                            {
                                title: 'Child Node3',
                                value: '7520669f3b20008c6a6df71e9cebc2f9sdfs',
                                key: '8'
                            },
                            {
                                title: 'Child Node4',
                                value: '7520669f3b20008c6a6df71e9cebc2f9wee',
                                key: '9'
                            },
                            {
                                title: 'Child Node5',
                                value: '7520669f3b20008c6a6df71e9cebc2f9weffds',
                                key: '1'
                            }
                        ]
                    }
                ],
                userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
                treeIds: [],
                templateVisible: false,
                templateData: [],
                templateTitle: ''
            }
        },
        mounted() {
            var myDate = new Date()
            var thisyear = myDate.getFullYear()
            var thismonth = myDate.getMonth() + 1
            let date = thisyear + '/' + thismonth + '/' + 1
            this.searchDate = [this.__moment__(date), this.__moment__(new Date())]
            if(this.$route.query.SchoolId){
                this.treeIds = [this.$route.query.SchoolId];
                this.searchId = this.$route.query.SchoolId;
            }
            let StartDate = this.__moment__(this.searchDate[0]).format('YYYY-MM-DD')
            let EndDate = this.__moment__(this.searchDate[1]).format('YYYY-MM-DD')
            let searchId = this.searchId ? this.searchId.substring(0, this.searchId.length - 1) : '';
            let data = {
                StartDate: this.searchDate.length != 0 ? StartDate : '',
                EndDate: this.searchDate.length != 0 ? EndDate : '',
                AreaId: searchId
            };
            this._infoEcharts2(data);
            this._info(data);
        },
        created() {
            this._treeData()
        },
        methods: {
            _sort(list, type, name, direction) {
                for (let i = 0; i < list.length; i++) {
                    for (let j = i; j < list.length; j++) {
                        let item = list[i]
                        let tab1 =
                            typeof list[i][type] == 'number'
                                ? list[i][type]
                                : Number(list[i][type].replace(/,|%/g, ''))
                        let tab2 =
                            typeof list[j][type] == 'number'
                                ? list[j][type]
                                : Number(list[j][type].replace(/,|%/g, ''))
                        if (direction == 'below') {
                            if (tab1 > tab2) {
                                list[i] = list[j];
                                list[j] = item;
                            }
                        } else {
                            if (tab1 < tab2) {
                                list[i] = list[j];
                                list[j] = item;
                            }
                        }
                    }
                }
                this.infoData[name] = list;
                let data = Object.assign({}, this.infoData);
                this.infoData = data
            },
            _treeData() {
                let self = this
                this.$axios.get(
                    1090,
                    {
                        UserId: self.userinfo.uid
                    },
                    res => {
                        self.treeData = res.data
                    }
                )
            },
            treechange(value) {
                let self = this;
                self.searchId = '';
                if (value.length > 0) {
                    for (var i = 0; i < value.length; i++) {
                        self.searchId += value[i] + ',';
                    }
                }
            },
            _skipSkimSP(id, name) {
                let data = {
                    type: 'task',
                    name: name,
                    id: id
                };
                window.parent.postMessage(data, '*')
            },
            _skipSkim(id, name) {
                let data = {
                    type: 'student',
                    name: name,
                    id: id
                };
                window.parent.postMessage(data, '*')
            },
            _info(data) {
                this.$message.loading('加载中...', 0)
                this.$axios.post(5836156, data, res => {
                    if (res.data.code == 1) {
                        this.infoData = res.data.data
                        window.sessionStorage.setItem('tuifei', JSON.stringify(res.data.data))
                        this._infoEcharts(this.infoData.charts)
                    }
                    this.$message.destroy()
                })
            },
            _search() {
                let StartDate = this.__moment__(this.searchDate[0]).format('YYYY-MM-DD');
                let EndDate = this.__moment__(this.searchDate[1]).format('YYYY-MM-DD');
                let searchId = this.searchId ? this.searchId.substring(0, this.searchId.length - 1) : '';
                let data = {
                    StartDate: this.searchDate.length != 0 ? StartDate : '',
                    EndDate: this.searchDate.length != 0 ? EndDate : '',
                    AreaId: searchId
                };
                this._info(data);
                this._infoEcharts2(data);
            },
            _reset() {
                this.relevanceText = '';
                this.searchDate = [];
                this.relevanceObject = [];
                this.searchId = '';
                this.treeIds = [];
            },
            _infoEcharts2(data) {
                self = this
                this.$axios.get(8000029, data, res => {
                    if (res.data.code == 1) {
                        console.log(res.data)
                        let babelList = []
                        let dataList = []
                        res.data.data.map(result1 => {
                            dataList.push({
                                value: result1.count,
                                name: result1.Content + '(' + result1.count + ')',
                                realname: result1.Content
                            });
                            babelList.push(result1.Content)
                        });
                        let echarts = require('echarts')
                        let myChart = echarts.init(document.getElementById('eaharts2'))
                        let option = {
                            title: {
                                text: '退费分布图',
                                left: 'center'
                            },
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b} : {c} ({d}%)'
                            },
                            legend: {
                                orient: 'vertical',
                                left: 'left',
                                data: babelList
                            },
                            series: [
                                {
                                    name: '退费原因',
                                    type: 'pie',
                                    radius: '55%',
                                    center: ['50%', '60%'],
                                    data: dataList,
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                }
                            ]
                        }

                        myChart.setOption(option, true)
                        window.addEventListener('resize', function () {
                            myChart.resize()
                        });
                        myChart.on('click', function (param) {
                            self.templateTitle = param.data.realname + ' 明细'
                            console.log(param.data.realname);
                            self.templateVisible = true;
                            data.Content = param.data.realname;
                            self.$axios.get(8000031, data, result => {
                                if (result.data.code == 1) {
                                    self.templateData = result.data.data;
                                }
                            })
                            // 获取data长度
                            // alert(option.series[0].data.length);
                            // //      获取地N个data的值
                            // // 　　alert(option.series[0].data[i]);
                            // //     获取series中param.dataIndex事件对应的值
                            // alert(param.value);
                            // alert(param.name);
                            // alert(option.series[param.seriesIndex].data[param.dataIndex].value);
                            // alert(option.series[param.seriesIndex].data[param.dataIndex].name);
                        })
                    }
                })
            },
            _infoEcharts(data) {
                let echarts = require('echarts');
                let myChart = echarts.init(document.getElementById('eaharts'));
                let list = [
                    {
                        type: 'line',
                        smooth: 0.6,
                        symbol: 'none',
                        lineStyle: {
                            color: 'rgb(252, 198, 163)',
                            width: 4
                        },
                        areaStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 0, [
                                    {
                                        offset: 0,
                                        color: 'rgb(252, 198, 163)'
                                    }
                                ])
                            }
                        },
                        data: data.low
                    },
                    {
                        type: 'line',
                        smooth: 0.6,
                        symbol: 'none',
                        lineStyle: {
                            color: 'rgb(241, 128, 105)',
                            width: 4
                        },
                        areaStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 0, [
                                    {
                                        offset: 0,
                                        color: 'rgb(241, 128, 105)'
                                    }
                                ])
                            }
                        },
                        data: data.mid
                    },
                    {
                        type: 'line',
                        smooth: 0.6,
                        symbol: 'none',
                        lineStyle: {
                            color: 'rgb(241, 82, 71)',
                            width: 4
                        },
                        areaStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 0, [
                                    {
                                        offset: 0,
                                        color: 'rgb(241, 82, 71)'
                                    }
                                ])
                            }
                        },
                        data: data.high
                    },
                    {
                        type: 'line',
                        smooth: 0.6,
                        symbol: 'none',
                        lineStyle: {
                            color: 'rgb(204, 17, 49)',
                            width: 4
                        },
                        areaStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 0, [
                                    {
                                        offset: 0,
                                        color: 'rgb(204, 17, 49)'
                                    }
                                ])
                            }
                        },
                        data: data.superhigh
                    }
                ];
                let option = {
                    backgroundColor: '', //背景颜色透明
                    tooltip: {
                        trigger: 'axis' //鼠标经过提示
                    },
                    grid: {
                        x: 45,
                        y: 25,
                        x2: 45,
                        y2: 35
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        splitLine: {
                            lineStyle: {
                                type: 'solid'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                        boundaryGap: [0, '30%'],
                        splitLine: {
                            lineStyle: {
                                type: 'dashed'
                            }
                        }
                    },
                    visualMap: {
                        type: 'piecewise',
                        show: false,
                        dimension: 0,
                        seriesIndex: 0,
                        pieces: [
                            {
                                gt: 1,
                                lt: 3,
                                color: 'rgba(0, 180, 0, 0.5)'
                            },
                            {
                                gt: 5,
                                lt: 7,
                                color: 'rgba(0, 180, 0, 0.5)'
                            }
                        ]
                    },
                    series: list
                };
                myChart.setOption(option, true);
                window.addEventListener('resize', function () {
                    myChart.resize()
                })
            },
            _relevanceObject(list) {
                let self = this;
                this.relevanceObject = list;
                for (let i = 0; i < list.length; i++) {
                    self.relevanceText += list[i].title + ',';
                    self.searchId += list[i].id + ','
                }
            },
            _showVisible(i) {
                this.visible = true
                let StartDate = this.__moment__(this.searchDate[0]).format('YYYY-MM-DD')
                let EndDate = this.__moment__(this.searchDate[1]).format('YYYY-MM-DD')
                let searchId = this.searchId
                    ? this.searchId.substring(0, this.searchId.length - 1)
                    : ''
                let data = {
                    StartDate: this.searchDate.length != 0 ? StartDate : '',
                    EndDate: this.searchDate.length != 0 ? EndDate : '',
                    AreaId: searchId
                }
                if (i == 1) {
                    this.$axios.get(5836157, data, res => {
                        if (res.data.code == 1) {
                            this.refundList = res.data.data
                        } else {
                            this.refundList = []
                        }
                    })
                } else {
                    this.$axios.get(5836158, data, res => {
                        if (res.data.code == 1) {
                            this.refundList = res.data.data
                        } else {
                            this.refundTasksList = []
                        }
                    })
                }
            },
            _drawerClose() {
                this.visible = false
            },
            _templateClose() {
                this.templateVisible = false
            }
        }
    }
</script>

<style scoped lang="less">
    .refundReport {
        .header {
            margin-bottom: 15px;
            .header-title {
                color: #242424;
            }
        }
        .main {
            flex: 1;
            .ranking {
                margin-top: 15px;
                .list {
                    height: 665px;
                    overflow-y: scroll;
                    flex: 1;
                    padding: 10px 15px;
                    background: #fff;
                    border-radius: 5px;
                    ul {
                        li {
                            div:nth-child(2) {
                                white-space: nowrap;
                            }
                            padding: 10px;
                            padding-right: 20px;
                            border-bottom: 1px solid #f6f6f6;
                            .money {
                                display: inline-block;
                                vertical-align: middle;
                                font-size: 20px;
                                color: #000;
                                line-height: 1;
                                width: 100px;
                            }
                            .num {
                                display: inline-block;
                                width: 42px;
                                height: 42px;
                                line-height: 44px;
                                margin-right: 15px;
                                text-align: center;
                                border-radius: 50%;
                                background: #f4f4f5;
                                color: #fb2f69;
                                font-size: 22px;
                                vertical-align: middle;
                            }
                            .centre-name {
                                display: inline-block;
                                color: #000;
                                font-size: 18px;
                                vertical-align: middle;
                                width: 100px;
                            }
                            .sup {
                                display: inline-block;
                                margin-left: 20px;
                                vertical-align: middle;
                                font-size: 20px;
                                line-height: 1;
                                min-width: 90px;
                                span {
                                    display: inline-block;
                                    vertical-align: bottom;
                                    line-height: 1;
                                    &:first-child {
                                        color: #fb2f69;
                                    }
                                }
                            }
                        }
                    }
                    .title {
                        padding-bottom: 10px;
                        padding-left: 15px;
                        color: #666;
                        font-size: 22px;
                        border-bottom: 1px solid #f6f6f6;
                    }
                    .min-title {
                        font-size: 12px !important;
                        color: #9e9e9e !important;
                    }
                }
            }
            .eaharts {
                width: 100%;
                height: 500px;
                padding: 20px;
                margin-top: 15px;
                background: #fff;
                border-radius: 10px;
                .eaharts-totality {
                    margin-top: 10px;
                    ul {
                        li {
                            flex: 1;
                            .tab-money {
                                font-size: 28px;
                                color: #242424;
                            }
                            .tab-title {
                                font-size: 20px;
                                color: #787878;
                                .tab-icon {
                                    display: inline-block;
                                    vertical-align: sub;
                                    width: 18px;
                                    height: 18px;
                                    margin-right: 10px;
                                    border-radius: 50%;
                                }
                                img {
                                    display: inline-block;
                                    vertical-align: middle;
                                    width: 20px;
                                    height: 20px;
                                    margin-right: 10px;
                                }
                                span {
                                    display: inline-block;
                                    vertical-align: middle;
                                }
                            }
                        }
                    }
                }
                .eaharts-header {
                    padding-bottom: 15px;
                    padding-left: 10px;
                    border-bottom: 1px solid #f1f1f1;
                    color: #666;
                    font-size: 22px;
                }
            }
            .summary {
                ul {
                    li {
                        &:last-child {
                            margin-right: 0;
                        }
                        flex: 1;
                        padding: 20px 15px;
                        padding-top: 30px;
                        margin-right: 15px;
                        height: 150px;
                        background: #fff;
                        border-radius: 8px;
                        .item-title {
                            margin-bottom: 18px;
                            color: #787878;
                            font-size: 18px;
                        }
                        .sum {
                            display: inline-block;
                            vertical-align: bottom;
                            color: #242424;
                            font-size: 30px;
                            line-height: 1;
                        }
                        .sup {
                            display: inline-block;
                            margin-left: 40px;
                            vertical-align: bottom;
                            font-size: 20px;
                            span {
                                display: inline-block;
                                vertical-align: bottom;
                                line-height: 1;
                                &:first-child {
                                    color: #fb2f69;
                                }
                            }
                        }
                    }
                }
            }
        }
        .sidebar {
            width: 350px;
            margin-left: 15px;
            .list {
                width: 100%;
                //height: 665px;
                padding: 10px 15px;
                background: #fff;
                border-radius: 5px;
                ul {
                    li {
                        padding: 10px 0;
                        border-bottom: 1px solid #f3f3f3;
                        .money {
                            color: #9e9e9e;
                            font-size: 17px;
                            span {
                                color: #000;
                                font-size: 20px;
                            }
                        }
                        .right-icon {
                            font-size: 18px;
                            color: #9e9e9e;
                        }
                        .user-img {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            margin-right: 10px;
                        }
                        .user-msg {
                            .icon-btn {
                                display: inline-block;
                                width: 10px;
                                height: 10px;
                                margin-right: 5px;
                                border-radius: 50%;
                                background: #cc1131;
                                vertical-align: middle;
                            }
                            .user-name {
                                font-size: 16px;
                                color: #000;
                                margin-right: 15px;
                            }
                            .user-centre {
                                color: #9e9e9e;
                                font-size: 16px;
                            }
                        }
                        .date {
                            font-size: 14px;
                            margin-top: 4px;
                            color: #9e9e9e;
                            img {
                                width: 14px;
                                height: 14px;
                                vertical-align: baseline;
                                margin-right: 2px;
                            }
                        }
                    }
                }
                .title {
                    padding-bottom: 10px;
                    color: #666;
                    font-size: 22px;
                    border-bottom: 1px solid #f6f6f6;
                }
            }
        }
        .person {
            position: absolute !important;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 1000;
        }
    }

    .draw {
        .list {
            width: 100%;
            //height: 665px;
            padding: 10px 15px;
            background: #fff;
            border-radius: 5px;
            ul {
                li {
                    padding: 10px 0;
                    border-bottom: 1px solid #f3f3f3;
                    .money {
                        color: #9e9e9e;
                        font-size: 17px;
                        span {
                            color: #000;
                            font-size: 20px;
                        }
                    }
                    .right-icon {
                        font-size: 18px;
                        color: #9e9e9e;
                    }
                    .user-img {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                    .user-msg {
                        .icon-btn {
                            display: inline-block;
                            width: 10px;
                            height: 10px;
                            margin-right: 5px;
                            border-radius: 50%;
                            background: #cc1131;
                            vertical-align: middle;
                        }
                        .user-name {
                            font-size: 16px;
                            color: #000;
                            margin-right: 15px;
                        }
                        .user-centre {
                            color: #9e9e9e;
                            font-size: 16px;
                        }
                    }
                    .date {
                        font-size: 14px;
                        margin-top: 4px;
                        color: #9e9e9e;
                        img {
                            width: 14px;
                            height: 14px;
                            vertical-align: baseline;
                            margin-right: 2px;
                        }
                    }
                }
            }
            .title {
                padding-bottom: 10px;
                color: #666;
                font-size: 22px;
                border-bottom: 1px solid #f6f6f6;
            }
        }
    }
</style>
